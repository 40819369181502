import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import Alert from '@mui/material/Alert';
import { Typography } from "@mui/material";
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from '@mui/material/IconButton';
import DashboardFileUpload from "../fileupload/DashboardFileUpload";
import UploadDialog from "../fileupload/UploadDialog";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { FaEnvelope } from "react-icons/fa6";

const MessagesPage = () => {


    const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } = useContext(AppContext);
    const [stats, setStats]: [any, any] = useState({
        totalEmployee: 0,
        totalAdmin: 1,
        totalGasTransactions: 0,
        totalArticles: 0,
        totalUsers: 0,
        totalMessages: 0
    });
    const getTotalEmployee = () => {
        axios.post(Tools.getHost() + "adminapi/showstats", {
            loginToken: localStorage.getItem("authToken")
        }).then((res: any) => {
            // console.log('stats data ');
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                // console.log('succeeded');
                setStats({
                    ...stats,
                    totalArticles: res.data.stats.totalArticles,
                    totalMessages: res.data.stats.totalMessages,
                    totalUsers: res.data.stats.totalUsers
                });
            } else {
                console.log('status */*/*/*/*', res.data.status);
            }
        })
    }
    const [fileStats, setFileStats] = useState({
        all: []
    })
    const getMessages = () => {
        axios.post(Tools.getHost() + 'adminapi/getmessages',
            {
                authToken: localStorage.getItem('authToken')
            }).then((res: any) => {

                setFileStats({
                    ...fileStats,
                    all: res.data.messages
                })
            })
    }
    const [fileUploaded, setFileUploaded] = useState(false);

    const checkFileUploaded = (response: any) => {
        setFileUploaded(response);
    }

    //upload dialog creation
    const [showUploadDialog, setShowUploadDialog] = useState(false);

    const appearUploadDialog = () => {
        setShowUploadDialog(true);
    }
    const closeUploadDialog = () => {
        setShowUploadDialog(false);
    }

    //selecting file from upload dialog 
    const [fileSelected, setFileSelected] = useState({ id: 0, fileName: '' });
    const selectFile = (aboutFile: any) => {
        setFileSelected(aboutFile);
    }
    React.useEffect(() => {
        //getTotalEmployee();
        getMessages();
    }, [])
    return <>
        <DashboardHeader />
        <div className="dashboard-core">
            <div className="left-content">
                <LeftMenu />
            </div>
            <div className="main-content">
                {/* <Alert severity="info">Cette application est en cours de construction par Francky. Veuillez revenir de temps en temps.</Alert>*/}

                <div>
                    <h3 className="elem-section-title"><FaEnvelope/> Messages</h3>
                    {fileStats.all.length <= 0 ? <><div className="elem-flex-center"><Alert severity="info">Aucun message trouvé</Alert></div></> : <></>}

                    <div className="messages-list">
                        {fileStats.all.length > 0 ? (<>
                            {fileStats.all.map((res: any) => {
                                return <article style={{ marginBottom: '15px', padding: '25px' }} className="album-article">
                                    <h3 style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px", minHeight: "35px" }}><AccountCircleRoundedIcon /><a href={"mailto:" + res.email} target="_blank">{res.email}</a></h3>
                                    <div>{res.message}</div>
                                </article>
                            })}
                        </>) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default MessagesPage;