import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
// import DashboardHeader from "../header/DashboardHeader";
// import LeftMenu from "../dashboard/LeftMenu";
import InputConsumptionGasDataTable from "./InputConsumptionGasDataTable";
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Box, Button, Typography, Modal, TextField, FormControl, Alert } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Person } from "@mui/icons-material";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import * as Tools from "../../utils/tools";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';

const InputConsumptionGasList = () => {

    const [allUsers, setAllUsers]: [any, any] = useState({
        all: []
    });
    //fetching all employees 
    //get employees
    const getEmployees = () => {
        axios.get(Tools.getHost() + "/api/manageuser").then(res => {

            if (res.data.length) {

                const totalLength = res.data.length;
                if (res.data[totalLength - 1].status === 'success') {
                    // console.log('my data');
                    //format employees 

                    // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                    let formatedDatas = [];
                    // formatedDatas.push({ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 })
                    for (let i = 0, c = res.data.length - 1; i < c; i++) {
                        // console.log(res.data[i].userName);
                        formatedDatas.push({ id: res.data[i].userId, userName: res.data[i].userName, userEmail: res.data[i].userEmail, userLogin: res.data[i].userLogin, userJob: res.data[i].userJob });

                    }
                    setAllUsers({
                        ...allUsers,
                        all: formatedDatas
                    });
                } else {
                    // console.log('error');
                }
            } else {
                // console.log('error');
            }

        })
    }
    const [fuelSupplyList, setFuelSupplyList]: [any, any] = useState({ all: [] });
    const getGasAssignmentList = () => {
        axios.post(Tools.getHost() + "/api/getgasassignmentbyemployeeid", {
            userId: localStorage.getItem('userId')
        }).then(res => {

            if (res.data.length) {

                const totalLength = res.data.length;
                if (res.data[totalLength - 1].status === 'success') {
                    // console.log('my data');
                    //format employees 

                    // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                    let formatedDatas = [];
                    // formatedDatas.push({ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 })
                    for (let i = 0, c = res.data.length - 1; i < c; i++) {
                        // console.log(res.data[i].userName);
                        formatedDatas.push({ id: res.data[i].id, userId: res.data[i].userId, quantity: res.data[i].quantity, reason: res.data[i].reason, date: res.data[i].date });

                    }
                    setFuelSupplyList({
                        ...fuelSupplyList,
                        all: formatedDatas
                    });
                } else {
                    // console.log('error');
                }
            } else {
                // console.log('error');
            }

        })
    }
    // create new user modal 
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        setCreated(false);
        setShowErrorForm(false);
        console.log('fetching all users');
        // getEmployees();
        getGasAssignmentList();
    }
    const handleClose = () => {
        setOpen(false);
        setCreated(false);
        setShowErrorForm(false);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid transparent',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    //delete modal 
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        setClickSubmitDelete(false);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        // setClickSubmitUpdate(false);
    }
    //udpate modal
    const [openUpdate, setOpenUpdate] = React.useState(false);

    const handleOpenUpdate = () => {
        setOpenUpdate(true);
        setUpdated(false);
        setShowErrorFormUpdate(false);
        setClickSubmitUpdate(false);
        getEmployees();
        getGasAssignmentList();
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setUpdated(false);
        setShowErrorFormUpdate(false);
        // setClickSubmitUpdate(false);
    }

    //handling user creation 
    const [created, setCreated] = useState(false);
    const [showErrorForm, setShowErrorForm] = useState(false);
    //creation
    const [creationState, setCreationState] = useState({
        userId: 0,
        quantity: 0,
        reason: '',
        supplyId: 0,
        km: 0,
        vehicleType: 0
    });
    const handleCreation = (e: any) => {
        setCreationState({
            ...creationState,
            [e.target.name]: e.target.value
        })
    }
    //update creation state 
    const [updateState, setUpdateState] = useState({
        id: 0,
        userId: 0,
        quantity: 0,
        reason: '',
        supplyId: 0,
        km: 0,
        vehicleType: 0
    });
    const handleUpdate = (e: any) => {
        setUpdateState({
            ...updateState,
            [e.target.name]: e.target.value
        })
    }
    //udpate

    const [showErrorFormUpdate, setShowErrorFormUpdate] = useState(false);
    const [userUpdateState, setUserUpdateState] = useState({
        userName: '',
        userEmail: '',
        userLogin: '',
        userPassword: '',
        userPost: '',
        userId: 0
    });

    const handleUserUpdate = (e: any) => {
        setUserUpdateState({
            ...userUpdateState,
            [e.target.name]: e.target.value
        })
    }

    //creation
    const [clickSubmit, setClickSubmit] = useState(false);
    const submitGasAssignmentCreation = (e: any) => {
        e.preventDefault();
        // console.log('Creating new user');
        setClickSubmit(true);
        axios.post(appHost + '/api/newgasconsumptioninput',
            {
                userId: creationState.userId || localStorage.getItem('userId'),
                quantity: creationState.quantity,
                reason: creationState.reason,
                supplyId: creationState.supplyId,
                km: creationState.km,
                vehicleType: creationState.vehicleType
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setCreated(true);
                setShowErrorForm(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmit(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setCreated(false);
                setShowErrorForm(true);
                setClickSubmit(false);
            }
        })
    }
    //update
    const [clickSubmitUpdate, setClickSubmitUpdate] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);
    const [updated, setUpdated] = useState(false);

    const submitUpdate = (e: any) => {
        e.preventDefault();
        // console.log('Creating new user');
        setClickSubmitUpdate(true);
        {/* userId: 0,
        quantity: 0,
        reason: '',
        supplyId: 0,
        km: 0,
        vehicleType: 0 */}
        axios.post(appHost + '/api/updatefuelconsumptionbyid',
            {
                id: updateState.id,
                quantity: updateState.quantity,
                reason: updateState.reason,
                userId: updateState.userId,
                supplyId: updateState.supplyId,
                km: updateState.km,
                vehicleType: updateState.vehicleType
            }
        ).then((res: any) => {
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUpdated(true);
                setShowErrorFormUpdate(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitUpdate(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUpdated(false);
                setShowErrorFormUpdate(true);
                setClickSubmitUpdate(false);
            }
        })
    }
    const submitUserUpdate = (e: any) => {
        e.preventDefault();
        console.log('Creating new user');
        setClickSubmitUpdate(true);
        axios.post(appHost + '/api/updategasassignment',
            {
                userName: userUpdateState.userName,
                userLogin: userUpdateState.userLogin,
                userEmail: userUpdateState.userEmail,
                userPassword: userUpdateState.userPassword,
                userJob: userUpdateState.userPost,
                userId: userUpdateState.userId
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserUpdated(true);
                setShowErrorFormUpdate(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitUpdate(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserUpdated(false);
                setShowErrorFormUpdate(true);
                setClickSubmitUpdate(false);
            }
        })
    }
    const toggleUpdateModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenUpdate();
        console.log('updating ' + articleId);
        axios.post(`${Tools.getHost()}/api/getfuelconsumptionbyid`, {
            id: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                //console.log('article found ', res.data);
                setUpdateState({
                    ...updateState,
                    id: res.data.id,
                    quantity: res.data.quantity,
                    userId: res.data.userId,
                    reason: res.data.reason,
                    km: res.data.distance_km,
                    supplyId: res.data.supplyId,
                    vehicleType: res.data.vehicleType
                });
            } else {
                console.log('some error occured');
            }
        })

    }
    const toggleDeleteModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenDelete();
        //console.log('deleting ' + articleId);
        axios.post(`${Tools.getHost()}/api/getfuelconsumptionbyid`, {
            id: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                console.log('article to delte found ', res.data);
                setUserDeleteState({
                    ...userDeleteState,
                    id: res.data.id,
                })
                console.log('===to delete ' + res.data.id);
            } else {
                console.log('some error occured');
            }
        })

    }
    //delete modal submit 
    const [clickSubmitDelete, setClickSubmitDelete] = useState(false);
    const [userDeleted, setUserDeleted] = useState(false);
    const [showErrorFormDelete, setShowErrorFormDelete] = useState(false);
    const submitUserDelete = (e: any) => {
        e.preventDefault();
        console.log('Deleting new user');
        setClickSubmitDelete(true);
        axios.post(appHost + '/api/deletefuelconsumptionbyid/',
            {
                id: userDeleteState.id
            }
        ).then((res: any) => {
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserDeleted(true);
                setShowErrorFormDelete(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitDelete(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserDeleted(false);
                setShowErrorFormDelete(true);
                setClickSubmitDelete(false);
            }
        })
    }
    const [userDeleteState, setUserDeleteState] = useState({
        id: 0
    })
    const handleUserDelete = (e: any) => {
        setUserDeleteState({
            ...userDeleteState,
            [e.target.name]: e.target.value
        })
    }
    const [assignTo, setAssignTo]: [any, any] = useState();
    const handleChangeAssignGas = (event: SelectChangeEvent) => {
        setAssignTo(event.target.value);
    };
    return <>
        <div className="main-content">
            <div className="elem-light-title">
                <h2>Saisie de votre consommation en carburant</h2>

                <Button aria-label="Adding user"
                    startIcon={<AddCircleOutlineRoundedIcon />} color="primary" onClick={handleOpen}>
                    Nouvelle saisie
                </Button>
            </div>
            {/* add new user modal */}
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <div style={{ overflowY: "scroll" }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Saisie consommation carburant
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                        <form action="" onSubmit={submitGasAssignmentCreation}>

                            {/* <TextField
                                label="Quantité carburant(en Litre)"
                                id="standard-start-adornment"
                                sx={{ m: 1, width: "95%" }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Litres</InputAdornment>,
                                }}
                                variant="standard"
                                name="quantity"
                                onChange={handleCreation}
                            /> */}
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Quantité carburant(en Litres)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={assignTo}
                                    onChange={handleCreation}
                                    label="Age"
                                    name="quantity"
                                >
                                    <MenuItem value={1}>
                                        1L
                                    </MenuItem>

                                    <MenuItem value={2}>
                                        2L
                                    </MenuItem>

                                    <MenuItem value={3}>
                                        3L
                                    </MenuItem>

                                    <MenuItem value={4}>
                                        4L
                                    </MenuItem>

                                    <MenuItem value={5}>
                                        5L
                                    </MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Raison consommation </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={assignTo}
                                    onChange={handleCreation}
                                    label="Age"
                                    name="reason"
                                >
                                    <MenuItem value={2}>
                                        Travail - Maison
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Travail - Intervention
                                    </MenuItem>
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                    {/* listing all users*/}
                                    {/* {allUsers.all.length > 0 ?
                                        allUsers.all.map((res: any) => {
                                            // console.log(res);
                                            return <MenuItem value={parseInt(res.id)}>{res.userName + "(" + res.userJob + ")"}</MenuItem>
                                        })
                                        : (<MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>)} */}
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Sélectionner approvisionnement</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={assignTo}
                                    onChange={handleCreation}
                                    label="Age"
                                    name="supplyId"
                                >
                                    {/* <MenuItem value={1}>
                                        Approvisionnement 1(date)
                                    </MenuItem> */}

                                    {fuelSupplyList.all.length > 0 ?
                                        fuelSupplyList.all.map((res: any) => {
                                            // console.log(res);
                                            return <MenuItem value={parseInt(res.id)}>{"Appro " + res.quantity + "L (" + res.date + ")"}</MenuItem>
                                        })
                                        : (<MenuItem value="">
                                            <em>Rien</em>
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Nombre de kilomètre parcourue(en km)"

                                sx={{ m: 1, width: "95%" }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">km</InputAdornment>,
                                }}
                                variant="standard"
                                name="km"
                                onChange={handleCreation}
                            />

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="vehicle-type-id">Type véhicule</InputLabel>
                                <Select
                                    labelId="vehicle-type-id"
                                    id="demo-simple-select-standard"
                                    value={assignTo}
                                    onChange={handleCreation}
                                    label="vehicleType"
                                    name="vehicleType"
                                >
                                    <MenuItem value={1}>
                                        Voiture
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Moto
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Scooter
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        Autre
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Litre carburant" name="userName" variant="standard" onChange={handleUserCreation} />
                                </FormControl> */}

                            {fuelSupplyList.all.length <= 0 ? (<>
                                <Alert severity="warning">Vous ne pouvez pas encore saisir votre consommation de carburants car vous n'avez pas encore été approvisionné en carburant.</Alert>
                            </>) : (<>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmit ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonAddIcon />}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Enregistrer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                            Enregister
                                        </Button></>}
                                </div>
                            </>)}
                            <br />
                            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: created ? "flex" : "none" }}>
                                Saisie de consommation de carburant réussie
                            </Alert>

                            <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorForm ? "flex" : "none" }}>
                                Une erreur s'est produite lors de la distribution du carburant de l'employé
                            </Alert>
                            {/* {creationState.quantity+" , "+creationState.userId} */}
                            {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                        </form>
                    </Box>
                </Modal>
            </div>

            {/* update modal */}
            <div style={{ overflowY: "scroll" }}>
                <Modal
                    open={openUpdate}
                    onClose={handleCloseUpdate}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Modification consommation carburant
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                        <form action="" onSubmit={submitUpdate}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Quantité carburant(en Litres)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={updateState.quantity}
                                    onChange={handleUpdate}
                                    label="Age"
                                    name="quantity"
                                >

                                    <MenuItem value={1}>
                                        1L
                                    </MenuItem>

                                    <MenuItem value={2}>
                                        2L
                                    </MenuItem>

                                    <MenuItem value={3}>
                                        3L
                                    </MenuItem>

                                    <MenuItem value={4}>
                                        4L
                                    </MenuItem>

                                    <MenuItem value={5}>
                                        5L
                                    </MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Raison consommation </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={updateState.reason}
                                    onChange={handleUpdate}
                                    label="Age"
                                    name="reason"
                                >
                                    <MenuItem value={2}>
                                        Travail - Maison
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Travail - Intervention
                                    </MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Sélectionner approvisionnement</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={updateState.supplyId}
                                    onChange={handleUpdate}
                                    label="Age"
                                    name="supplyId"
                                >
                                    {/* <MenuItem value={1}>
                                        Approvisionnement 1(date)
                                    </MenuItem> */}

                                    {fuelSupplyList.all.length > 0 ?
                                        fuelSupplyList.all.map((res: any) => {
                                            // console.log(res);
                                            return <MenuItem value={parseInt(res.id)}>{"Appro " + res.quantity + "L (" + res.date + ")"}</MenuItem>
                                        })
                                        : (<MenuItem value="">
                                            <em>Rien</em>
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Nombre de kilomètre parcourue(en km)"

                                sx={{ m: 1, width: "95%" }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">km</InputAdornment>,
                                }}
                                variant="standard"
                                value={updateState.km}
                                name="km"
                                onChange={handleUpdate}
                            />

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="vehicle-type-id">Type véhicule</InputLabel>
                                <Select
                                    labelId="vehicle-type-id"
                                    id="demo-simple-select-standard"
                                    value={updateState.vehicleType}
                                    onChange={handleUpdate}
                                    label="vehicleType"
                                    name="vehicleType"
                                >
                                    <MenuItem value={1}>
                                        Voiture
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Moto
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Scooter
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        Autre
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {/* <TextField
                                label="Quantité carburant(en Litre)"
                                id="standard-start-adornment"
                                sx={{ m: 1, width: "95%" }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Litres</InputAdornment>,
                                }}
                                variant="standard"
                                name="quantity"
                                onChange={handleUpdate}
                                value={updateState.quantity}
                            />
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Assigné à </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={updateState.userId}
                                    onChange={handleUpdate}
                                    label="Age"
                                    name="userId"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    
                                    {allUsers.all.length > 0 ?
                                        allUsers.all.map((res: any) => {
                                            // console.log(res);
                                            return <MenuItem value={parseInt(res.id)}>{res.userName + "(" + res.userJob + ")"}</MenuItem>
                                        })
                                        : (<MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>)}
                                </Select>
                            </FormControl> */}

                            {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                {clickSubmitUpdate ? <>
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<PersonAddIcon />}
                                        variant="outlined"
                                        type="submit"
                                    >
                                        Enregistrer
                                    </LoadingButton></> : <>
                                    <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                        Enregister
                                    </Button></>}


                            </div> <br />
                            <input type="hidden" name="userId" value={updateState.userId} onChange={handleUpdate} />
                            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: updated ? "flex" : "none" }}>
                                L' approvisionnement en carburant pour cet employé a été modifié
                            </Alert>

                            <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormUpdate ? "flex" : "none" }}>
                                Une erreur s'est produite lors de la modification
                            </Alert>
                            {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                        </form>
                    </Box>
                </Modal>
            </div>
            {/*delete modal */}
            <div style={{ overflowY: "scroll" }}>
                <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Confirmation de la suppression?
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                        <form action="" onSubmit={submitUserDelete}>



                            {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                {clickSubmitDelete ? <>
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<PersonRemoveIcon />}
                                        variant="outlined"
                                        type="submit"
                                        color="secondary"
                                    >
                                        Effacer
                                    </LoadingButton></> : <>
                                    <Button variant="outlined" color="secondary" startIcon={<PersonRemoveIcon />} type="submit">
                                        Effacer
                                    </Button></>}


                            </div> <br />
                            <input type="hidden" name="id" value={userDeleteState.id} onChange={handleUserDelete} />
                            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userDeleted ? "flex" : "none" }}>
                                La transaction a bien été supprimé
                            </Alert>

                            <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormDelete ? "flex" : "none" }}>
                                Une Erreur s'est produite lors de la suppréssion de la transaction
                            </Alert>
                            {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                        </form>
                    </Box>
                </Modal>
            </div>
            {/* dataTable for users */}
            <InputConsumptionGasDataTable userDeleted={userDeleted} userUpdated={updated} newUserCreated={created} showModalUpdate={openUpdate} toggleUpdate={toggleUpdateModal} toggleDelete={toggleDeleteModal} />
        </div> {/*end main content*/}


    </>
}


export default InputConsumptionGasList;