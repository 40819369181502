import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import Alert from '@mui/material/Alert';
import { Typography } from "@mui/material";
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from '@mui/material/IconButton';
import DashboardFileUpload from "./DashboardFileUpload";
import CloseIcon from "@mui/icons-material/Close";

const UploadDialog= (prop:any) => {

    const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } = useContext(AppContext);
    const [stats, setStats]: [any, any] = useState({
        totalEmployee: 0,
        totalAdmin: 1,
        totalGasTransactions: 0,
        totalArticles: 0, 
        totalUsers: 0, 
        totalMessages: 0
    });
    const getTotalEmployee = () => {
        axios.post(Tools.getHost() + "adminapi/showstats", {
            loginToken: localStorage.getItem("authToken")
        }).then((res: any) => {
            // console.log('stats data ');
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                // console.log('succeeded');
                setStats({
                    ...stats,
                    totalArticles: res.data.stats.totalArticles,
                    totalMessages: res.data.stats.totalMessages, 
                    totalUsers: res.data.stats.totalUsers
                });
            } else {
                console.log('status */*/*/*/*', res.data.status);
            }
        })
    }
    const [fileStats, setFileStats]=useState({
        all: []
    })
    const getFiles = ()=>{
        axios.post(Tools.getHost()+'adminapi/getfiles',
            {loginToken: localStorage.getItem('loginToken')}).then((res:any)=>{

             // console.log(res.data)
             setFileStats({
                ...fileStats, 
                all: res.data.files
             })
        })
    }
    const [fileUploaded, setFileUploaded]=useState(false);

    const checkFileUploaded=(response:any) => {
        setFileUploaded(response);
    } 
    const selectFile =(aboutFile:any)=>{
      prop.select(aboutFile); 
      setShowContinue(true); 
      
    } 
    const continueUploading=() =>{
      prop.continueDialog();
    }
    const [showContinue, setShowContinue]=useState(false);
    const [currentSelected, setCurrentSelected]=useState(null);

    const [showState,setShowState]=useState(false);
    const closeDialog =()=>{
         prop.continueDialog();
    }
    const checkShowState= ()=>{
        if(prop.appear){
          setShowState(true);
        }
    }
    React.useEffect(() => {
        getTotalEmployee();
        getFiles();
        // checkShowState();
    }, [fileUploaded])
    return <> 
        <div className="upload-dialog-modal" style={{display: prop.appear?"block":"none"}}>
             
            <div className="upload-dialog-box upload-dialog-main-content">
            {/*close modal icon*/} 
             <CloseIcon onClick={closeDialog} style={{position:"absolute",top: "5px",right: "5px",cursor:'pointer'}}/>
             <div className="upload-modal-main"> 
                <h2>Upload</h2>
               {/* <Alert severity="info">Cette application est en cours de construction par Francky. Veuillez revenir de temps en temps.</Alert>*/}
               <div className="file-upload-container">
               <DashboardFileUpload isFileUploaded={checkFileUploaded}/>
               </div>
               <div className="file-list-container">
               <h3 className="elem-section-title">Liste des fichiers</h3>
               {fileStats.all.length<=0?<><div className="elem-flex-center"><Alert severity="info">Pas de fichiers trouvés</Alert></div></>:<></>}
               <div className="file-album">
               {fileStats.all.length>0?(<>
                {fileStats.all.map((res:any)=>{
                  {/*  console.log(res);*/}
                  return <article key={res.id} className={res.id===currentSelected?"album-article-selected album-article": "album-article"}  >
                  <img src={Tools.getHost()+'upload/datas/image/'+res.fileName} onClick={()=>{
                    selectFile(res);
                    setCurrentSelected(res.id)
                  }} alt=""/><br/>
                  </article>
                })}
                </>):(<></>)}
               </div>
               </div>
               </div> 
               <div className="upload-modal-footer" style={{display: showContinue?"flex":"none"}}> 
               <button className="app-bt" onClick={continueUploading} >Continuer</button>
               </div>
            </div>
        </div>
    </>
}


export default UploadDialog;