import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "./LeftMenu";
import Alert from '@mui/material/Alert';
import { Typography } from "@mui/material";
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from '@mui/material/IconButton';

const Dashboard = () => {


    const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } = useContext(AppContext);
    const [stats, setStats]: [any, any] = useState({
        totalEmployee: 0,
        totalAdmin: 1,
        totalGasTransactions: 0,
        totalArticles: 0, 
        totalUsers: 0, 
        totalMessages: 0,
        totalFiles: 0,
        totalTemplates: 0
    });
    const getTotalEmployee = () => {
        axios.post(Tools.getHost() + "adminapi/showstats", {
            authToken: localStorage.getItem("authToken")
        }).then((res: any) => {
            // console.log('stats data ');
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                // console.log('succeeded');
                setStats({
                    ...stats,
                    totalArticles: res.data.stats.totalArticles,
                    totalMessages: res.data.stats.totalMessages, 
                    totalUsers: res.data.stats.totalUsers,
                    totalFiles: res.data.stats.totalFiles, 
                    totalTemplates: res.data.stats.totalTemplates
                });
            } else {
                console.log('status */*/*/*/*', res.data.status);
            }
        })
    }
    React.useEffect(() => {
        getTotalEmployee();
    }, [])
    return <>
        <DashboardHeader />
        <div className="dashboard-core">
            <div className="left-content">
                <LeftMenu />
            </div>
            <div className="main-content">
                <h2 className="elem-light-title">Tableau de Bord</h2>
               {/* <Alert severity="info">Cette application est en cours de construction par Francky. Veuillez revenir de temps en temps.</Alert>*/}
                <div className="basic-dashboard-stats">
                    <div className="dashboard-item-wrap" style={{flexWrap:'wrap'}}>
                        <div className="dashboard-item">
                            <Typography variant="h4" sx={{ fontSize: '20px' }}>
                                Articles
                            </Typography>
                            <IconButton aria-label="Adding user" color="primary"  >
                                <span>{stats.totalArticles}</span>
                            </IconButton>
                        </div>
                        <div className="dashboard-item">
                            <Typography variant="h4" sx={{ fontSize: '20px' }}>
                                Utilisateurs
                            </Typography>
                            <IconButton aria-label="Adding user" color="primary"  >
                                <span>{stats.totalUsers}</span>
                            </IconButton>
                        </div>

                        {/* total transactions */}
                        <div className="dashboard-item">
                            <Typography variant="h4" sx={{ fontSize: '20px' }}>
                                Messages
                            </Typography>
                            <IconButton aria-label="Adding user" color="primary"  >
                                <span>{stats.totalMessages}</span>
                            </IconButton>
                        </div>
                        <div className="dashboard-item">
                            <Typography variant="h4" sx={{ fontSize: '20px' }}>
                               Fichiers
                            </Typography>
                            <IconButton aria-label="Adding user" color="primary"  >
                                <span>{stats.totalFiles}</span>
                            </IconButton>
                        </div>
                        <div className="dashboard-item">
                            <Typography variant="h4" sx={{ fontSize: '20px' }}>
                               Templates
                            </Typography>
                            <IconButton aria-label="Adding user" color="primary"  >
                                <span>{stats.totalTemplates}</span>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default Dashboard;