import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu"; 
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from '@mui/material/IconButton';
import DashboardFileUpload from "../fileupload/DashboardFileUpload";
import UploadDialog from "../fileupload/UploadDialog"; 
import { Box, Button, Typography, Modal, TextField, FormControl, Alert } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem'; 
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from "@mui/lab"; 
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';






const AppSettings = () => {

    const [assignTo, setAssignTo]: [any, any] = useState();
    const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } = useContext(AppContext);
    const [stats, setStats]: [any, any] = useState({
        totalEmployee: 0,
        totalAdmin: 1,
        totalGasTransactions: 0,
        totalArticles: 0,
        totalUsers: 0,
        totalMessages: 0
    });
    const getTotalEmployee = () => {
        axios.post(Tools.getHost() + "adminapi/showstats", {
            loginToken: localStorage.getItem("authToken")
        }).then((res: any) => {
            // console.log('stats data ');
            // console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                // console.log('succeeded');
                setStats({
                    ...stats,
                    totalArticles: res.data.stats.totalArticles,
                    totalMessages: res.data.stats.totalMessages,
                    totalUsers: res.data.stats.totalUsers
                });
            } else {
                console.log('status */*/*/*/*', res.data.status);
            }
        })
    }
    const [templates, setTemplates] = useState({
        all: []
    })
    const getTemplates = () => {
        axios.post(Tools.getHost() + 'adminapi/getAllTemplates',
            { authToken: localStorage.getItem('authToken') })
            .then((res: any) => {
 
                setTemplates({
                    ...templates,
                    all: res.data.templates
                })
            })
    }
    const [fileUploaded, setFileUploaded] = useState(false);

    const checkFileUploaded = (response: any) => {
        setFileUploaded(response);
    }

    //upload dialog creation
    const [showUploadDialog, setShowUploadDialog] = useState(false);

    const appearUploadDialog = () => {
        setShowUploadDialog(true);
    }
    const closeUploadDialog = () => {
        setShowUploadDialog(false);
    }

    //selecting file from upload dialog 
    const [fileSelected, setFileSelected] = useState({ id: 0, fileName: '' });
    const selectFile = (aboutFile: any) => {
        setFileSelected(aboutFile);
    }
    const [articleCreationState, setArticleCreationState] = useState({
        title: '',
        content: '',
        category: '',
        previewImageId: 0,
        previewImageSrc: '',
        templateId: 0
    })
    const handleCreation = (e: any) => {
        setArticleCreationState({
            ...articleCreationState,
            [e.target.name]: e.target.value
        })
    } 

    const [clickSubmit,setClickSubmit]=useState(false);
    const [settingsSaved, setSettingsSaved]=useState(false);
    const submitSettings = (e:any)=>{
        e.preventDefault();
        setClickSubmit(true);
        axios.post(appHost + 'adminapi/saveSettings',{
                // title: articleCreationState.title,
                // content: newNote,
                // category: articleCreationState.category,
                // previewImageId: fileSelected.id,
                // previewImageSrc: fileSelected.fileName,
                templateId: articleCreationState.templateId
        }).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                // setUserCreated(true);
                // setShowErrorForm(false);
                //added new user, should update list
                e.target.reset();
                setSettingsSaved(true);
                setClickSubmit(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                // setUserCreated(false);
                // setShowErrorForm(true);

                setSettingsSaved(false);
                setClickSubmit(false);
            }
        })


    }
    const [defaultTemplate, setDefaultTemplate]=useState(0);
    const getDefaultTemplate =()=>{
          axios.post(appHost + 'adminapi/getdefaulttemplate',{
          loginToken: localStorage.getItem('loginToken')
        }).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setDefaultTemplate(res.data.template);
            } else if (res.data.status.toLowerCase() === 'error') {
                
            }
        })        
    }
    React.useEffect(() => {
        // getTotalEmployee();
        getDefaultTemplate();
        getTemplates(); 
    }, [])
    return <>
        <DashboardHeader />
        <div className="dashboard-core">
            <div className="left-content">
                <LeftMenu />
            </div>
            <div className="main-content">
                {/*<h2 className="elem-light-title">Paramètres</h2>*/}
                 
                <div>
                    <h3 className="elem-section-title">Paramètres</h3> 
                 
                    <form onSubmit={submitSettings}>

                                       <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label2">Template par défaut</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label2"
                                            id="demo-simple-select-standard"
                                            value={defaultTemplate}
                                            onChange={handleCreation}
                                            label="templateType"
                                            name="templateId"  
                                        >
                                            <MenuItem value={0}>
                                                <em>Choisir template</em>
                                            </MenuItem>
                                            {templates.all.map((res:any)=>{
                                                {/*console.log(res);*/}
                                                return <MenuItem value={res.id}>
                                                <em>{res.fileTitle}</em>
                                            </MenuItem>
                                            })}  
                                        </Select> 
                                        </FormControl>  
                                        {clickSubmit ? <>
                                            <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                startIcon={<PersonAddIcon />}
                                                variant="outlined"
                                                type="submit"
                                            >
                                                Enregistrer
                                            </LoadingButton></> : <>
                                            <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                                Enregister
                                            </Button></>} 
                                     <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: settingsSaved ? "flex" : "none" }}>
                                        Vos paramètres ont été enregistré
                                    </Alert>
                    </form>
                    {/*<div className="file-album">
                        {fileStats.all.length > 0 ? (<>
                            {fileStats.all.map((res: any) => {
                                console.log(res);
                                return <article className="album-article">
                                    <a href={Tools.getHost()} target="_blank">{res.fileTitle}</a> 
                                </article>
                            })}
                        </>) : (<></>)}
                    </div>*/}
                </div>
            </div>
        </div>
    </>
}


export default AppSettings;