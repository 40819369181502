import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import UsersDataTable from "./UsersDataTable";
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Box, Button, Typography, Modal, TextField, FormControl, Alert } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Person } from "@mui/icons-material";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import * as Tools from "../../utils/tools";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Select, { SelectChangeEvent } from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem';  
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';

const UsersList = () => {

    // create new user modal 
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        setUserCreated(false);
        setShowErrorForm(false);
    }
    const handleClose = () => {
        setOpen(false);
        setUserCreated(false);
        setShowErrorForm(false);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid transparent',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    //delete modal 
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        setClickSubmitDelete(false);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        // setClickSubmitUpdate(false);
    }
    //udpate modal
    const [openUpdate, setOpenUpdate] = React.useState(false);

    const handleOpenUpdate = () => {
        setOpenUpdate(true);
        setUserUpdated(false);
        setShowErrorFormUpdate(false);
        setClickSubmitUpdate(false);
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setUserUpdated(false);
        setShowErrorFormUpdate(false);
        // setClickSubmitUpdate(false);
    }

    //handling user creation 
    const [userCreated, setUserCreated] = useState(false);
    const [showErrorForm, setShowErrorForm] = useState(false);
    //creation
    const [userCreationState, setUserCreationState] = useState({
        userName: '',
        userEmail: '',
        userLogin: '',
        userPassword: '',
        userPost: '',
        userCategory: '',
        userId: 0
    });
    const handleUserCreation = (e: any) => {
        setUserCreationState({
            ...userCreationState,
            [e.target.name]: e.target.value
        })
    }
    //udpate

    const [showErrorFormUpdate, setShowErrorFormUpdate] = useState(false);
    const [userUpdateState, setUserUpdateState] = useState({
        userName: '',
        userEmail: '',
        userLogin: '',
        userPassword: '',
        userPost: '',
        userId: 0
    });

    const handleUserUpdate = (e: any) => {
        setUserUpdateState({
            ...userUpdateState,
            [e.target.name]: e.target.value
        })
    }

    //creation
    const [clickSubmit, setClickSubmit] = useState(false);
    const submitUserCreation = (e: any) => {
        e.preventDefault();
        console.log('Creating new user');
        setClickSubmit(true);
        axios.post(appHost + '/adminapi/adduser',
            {
                userName: userCreationState.userName,
                userLogin: userCreationState.userLogin,
                userEmail: userCreationState.userEmail,
                userPassword: userCreationState.userPassword,
                userCategory: userCreationState.userCategory
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserCreated(true);
                setShowErrorForm(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmit(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserCreated(false);
                setShowErrorForm(true);

                setClickSubmit(false);
            }
        })
    }
    //update
    const [clickSubmitUpdate, setClickSubmitUpdate] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);

    const submitUserUpdate = (e: any) => {
        e.preventDefault();
        console.log('Creating new user');
        setClickSubmitUpdate(true);
        axios.post(appHost + '/adminapi/updateuserbyid',
            {
                userName: userUpdateState.userName,
                userLogin: userUpdateState.userLogin,
                userEmail: userUpdateState.userEmail,
                userPassword: userUpdateState.userPassword,
                userJob: userUpdateState.userPost,
                userId: userUpdateState.userId
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserUpdated(true);
                setShowErrorFormUpdate(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitUpdate(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserUpdated(false);
                setShowErrorFormUpdate(true);
                setClickSubmitUpdate(false);
            }
        })
    }
    const toggleUpdateModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenUpdate();
        console.log('updating ' + articleId);
        axios.post(Tools.getHost() + "/adminapi/getuserbyid", {
            userId: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                console.log('article found ', res.data);

                setUserUpdateState({
                    ...userUpdateState,
                    userName: res.data.userName,
                    userId: res.data.userId,
                    userPost: res.data.userJob,
                    userEmail: res.data.userEmail,
                    userLogin: res.data.userLogin
                })
                console.log('rocket here');

            } else {
                console.log('some error occured');
            }
        })

    }
    const toggleDeleteModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenDelete();
        //console.log('deleting ' + articleId);
        axios.post(Tools.getHost() + "/adminapi/getuserbyid", {
            userId: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                console.log('article to delte found ', res.data);
                setUserDeleteState({
                    ...userDeleteState,
                    userId: res.data.userId,
                })
                console.log('===to delete ' + res.data.userId);
            } else {
                console.log('some error occured');
            }
        })

    }
    //delete modal submit 
    const [clickSubmitDelete, setClickSubmitDelete] = useState(false);
    const [userDeleted, setUserDeleted] = useState(false);
    const [showErrorFormDelete, setShowErrorFormDelete] = useState(false);
    const submitUserDelete = (e: any) => {
        e.preventDefault();
        console.log('Deleting new user');
        setClickSubmitDelete(true);
        axios.post(appHost + '/adminapi/deleteuserbyid',
            {
                userId: userDeleteState.userId
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserDeleted(true);
                setShowErrorFormDelete(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitDelete(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserDeleted(false);
                setShowErrorFormDelete(true);
                setClickSubmitDelete(false);
            }
        })
    }
    const [userDeleteState, setUserDeleteState] = useState({
        userId: 0
    })
    const handleUserDelete = (e: any) => {
        setUserDeleteState({
            ...userDeleteState,
            [e.target.name]: e.target.value
        })
    }
    //search feature 
    const [enableSearch, setEnableSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
        search: ''
    });
    const searchUser = () => {
        setEnableSearch(true);
        //setSearchQuery(searchQuery.search);
    }
    const closeSearch = () => {
        setEnableSearch(false);
    }
    const handleSearch = (e: any) => {
        setEnableSearch(true);
        //add custom code to handle on change to get all employees
        setSearchQuery({
            ...searchQuery,
            [e.target.name]: e.target.value
        })
    }
    const submitUserSearch = (e: any) => {
        e.preventDefault();
        searchUser();
    }

    return <>
        <DashboardHeader />
        <div className="dashboard-core">
            <div className="left-content">
                <LeftMenu />
            </div>
            <div className="main-content">
                <div className="elem-light-title">
                    <div className="elem-row-flex">
                        <div>
                            <h2>Gestion des utilisateurs</h2>
                            {/* <IconButton aria-label="Adding user" color="primary" onClick={handleOpen}>
                        <AddCircleOutlineRoundedIcon />
                    </IconButton> */}
                            <Button aria-label="Adding user"
                                startIcon={<AddCircleOutlineRoundedIcon />} color="primary" onClick={handleOpen}>
                                Créer
                            </Button>
                        </div>
                        <div className="search-form-col">
                            <form onSubmit={submitUserSearch} >
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                    <InputLabel htmlFor="search-input-top">Recherche</InputLabel>
                                    <Input
                                        id="search-input-top"
                                        type="text"
                                        onChange={handleSearch}
                                        name="search"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="User search"
                                                    onClick={searchUser}
                                                    onMouseDown={searchUser}

                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </form>
                            {/* {searchQuery.search} */}
                        </div>
                    </div>
                </div>

                {/* add new user modal */}
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Ajout utilisateur
                            </Typography>
                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitUserCreation}>
                                <div className="elem-flex"><FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Nom complet" name="userName" variant="standard" onChange={handleUserCreation} />
                                </FormControl>

                                    <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                        <TextField id="standard-basic" label="Pseudo" name="userLogin" variant="standard" onChange={handleUserCreation} />
                                    </FormControl>
                                </div>

                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Adresse e-mail" name="userEmail" variant="standard" onChange={handleUserCreation} />
                                </FormControl>
{/* 
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Catégorie" name="userPost" variant="standard" onChange={handleUserCreation} />
                                </FormControl> */}
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label2">Template par défaut</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label2"
                                        id="demo-simple-select-standard"
                                        value={0}
                                        onChange={handleUserCreation}
                                        label="Catégorie"
                                        name="userCategory"
                                    >
                                        <MenuItem value={0}>
                                            <em>Choisir type utilisateur</em>
                                        </MenuItem>
                                        <MenuItem value={"user"}>User</MenuItem>
                                        
                                        <MenuItem value={"moderator"}>Moderator</MenuItem>
                                        
                                        <MenuItem value={"administrator"}>Administrator</MenuItem>
                                        {/* {templates.all.map((res: any) => {
                                             
                                            return <MenuItem value={res.id}>
                                                <em>{res.fileTitle}</em>
                                            </MenuItem>
                                        })} */}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserCreation} />
                                </FormControl>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmit ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonAddIcon />}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Enregistrer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                            Enregister
                                        </Button></>}


                                </div> <br />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userCreated ? "flex" : "none" }}>
                                    L'employé a bien été enregistré
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorForm ? "flex" : "none" }}>
                                    Erreur: Cet employé existe déjà: login ou email existant ou bien vous n'avez pas rempli correctement le formulaire.
                                </Alert>
                                {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                            </form>
                        </Box>
                    </Modal>
                </div>

                {/* update modal */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={openUpdate}
                        onClose={handleCloseUpdate}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ overflowY: "scroll" }}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modification employé
                            </Typography>
                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitUserUpdate}>
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Nom complet" name="userName" variant="standard" onChange={handleUserUpdate} value={userUpdateState.userName} />
                                </FormControl>

                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Pseudo" name="userLogin" variant="standard" onChange={handleUserUpdate} value={userUpdateState.userLogin} />
                                </FormControl>

                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Adresse e-mail" name="userEmail" variant="standard" onChange={handleUserUpdate} value={userUpdateState.userEmail} />
                                </FormControl>

                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Poste" name="userPost" variant="standard" onChange={handleUserUpdate} value={userUpdateState.userPost} />
                                </FormControl>

                                {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmitUpdate ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonAddIcon />}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Enregistrer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                            Enregister
                                        </Button></>}


                                </div> <br />
                                <input type="hidden" name="userId" value={userUpdateState.userId} onChange={handleUserUpdate} />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userUpdated ? "flex" : "none" }}>
                                    L'employé a bien été modifié
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormUpdate ? "flex" : "none" }}>
                                    Une Erreur s'est produite lors de la modification de l'employé
                                </Alert>
                                {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                            </form>
                        </Box>
                    </Modal>
                </div>
                {/*delete modal */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Voulez-vous vraiment effacé cet employé?
                            </Typography>
                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitUserDelete}>



                                {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmitDelete ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonRemoveIcon />}
                                            variant="outlined"
                                            type="submit"
                                            color="secondary"
                                        >
                                            Effacer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" color="secondary" startIcon={<PersonRemoveIcon />} type="submit">
                                            Effacer
                                        </Button></>}


                                </div> <br />
                                <input type="hidden" name="userId" value={userDeleteState.userId} onChange={handleUserDelete} />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userDeleted ? "flex" : "none" }}>
                                    L'employé a bien été supprimé
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormDelete ? "flex" : "none" }}>
                                    Une Erreur s'est produite lors de la suppréssion de l'employé
                                </Alert>
                                {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                            </form>
                        </Box>
                    </Modal>
                </div>
                {/* dataTable for users */}
                <UsersDataTable searchQuery={searchQuery.search} enableSearch={enableSearch} closeSearch={closeSearch} userDeleted={userDeleted} userUpdated={userUpdated} newUserCreated={userCreated} showModalUpdate={openUpdate} toggleUpdate={toggleUpdateModal} toggleDelete={toggleDeleteModal} />
            </div>
        </div>

    </>
}


export default UsersList;