import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import axios from "axios";
import parse from "html-react-parser";
import { HiUser } from "react-icons/hi";
import {
	BsArrowRight as ArrowRight,
	BsArrowLeft as ArrowLeft, BsPlusCircle as AddIcon, BsArrowUp as TopIcon
} from "react-icons/bs";
import * as tools from "../utils/tools";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';


export const Subscribe = () => {


	const [subscribeState, setSubscribeState]: [any, any] = useState({
		userName: '',
		userCategory: '',
		userEmail: '',
		userPassword: ''
	});

	const [saveState, setSaveState] = useState({
		saved: false
	});

	const [errorState, setErrorState] = useState({
		error: ''
	});

	const [checkPassword, setCheckPassword] = useState(false);

	const [submitError, setSubmitError] = useState(false);
	const submitSubscribeForm = (e: any) => {
		e.preventDefault();

		if (checkPassword) {
			setSubmitError(false);
			axios.post(baseURL + 'usersubscribe/',
				{
					userCategory: subscribeState.userCategory,
					userName: subscribeState.userName,
					userEmail: subscribeState.userEmail,
					userPassword: subscribeState.userPassword
				}).then((res: any) => {

					if (res.data.status === 'success') {
						setSaveState({

							...saveState,
							saved: true
						});

						setErrorState({

							...errorState,
							error: 'success'
						});
					} else {
						setErrorState({

							...errorState,
							error: 'error'
						});
					}
				});
		} else {
			setSubmitError(true);
		}

	}


	const handleSubscribeChange = (e: any) => {
		console.log(e.target.type);
		if (e.target.type === 'password') {
			if (e.target.value.length >= 8) {
				setCheckPassword(true);
			}
		}
		setSubscribeState({
			...subscribeState,
			[e.target.name]: e.target.value
		});

	}

	return <>
		{/*	
<Header/>*/}
		<div className="app-core">

			<div className="app-login">

				<div className="app-login-wrap">
					<div className="message-sent" style={{ display: saveState.saved ? "block" : "none" }}>
						<p><Link to="/login">{tools.translate('Bravo, vous êtes inscrit, rendez-vous sur la page de login maintenant')}</Link></p>
					</div>
					<form className="login-form" onSubmit={submitSubscribeForm} style={{ display: saveState.saved ? "none" : "block" }}>
						<div className="login-title">

							<h2 className="elem-gap-25p  elem-width-full ">{tools.translate('Subscribe')}</h2>
						</div>
						<label>{tools.translate('Who are you?')}
							<select name="userCategory" defaultValue="client" onChange={handleSubscribeChange}>

								<option value="client">{tools.translate('Choose profile')}</option>
								<option value="client">{tools.translate('Client')}</option>
								<option value="partenaires">{tools.translate('Sponsor')}</option>
								<option value="ami">{tools.translate('Friend')}</option>
								<option value="donate">{tools.translate('Donate')}</option>
								<option value="students">{tools.translate('Student')}</option>

							</select></label><br />
						<label>{tools.translate('Fullname')}
							<input type="text" name="userName" onChange={handleSubscribeChange} />
						</label>
						<br />
						<label>{tools.translate('Your email')}
							<input type="text" name="userEmail" onChange={handleSubscribeChange} />
						</label>
						<br />
						<label>{tools.translate('Your password')}
							<input type="password" name="userPassword" onChange={handleSubscribeChange} />
						</label>
						<div className="app-error" style={{ display: submitError ? "block" : "none" }}>{tools.translate('Mila 8 na mihoatra ny halavan\' ilay teny miafina')}</div>
						<br />
						<div className="login-footer">
							<button className="app-bt" onClick={submitSubscribeForm}>{tools.translate('Subscribe')}</button>

							<Link to="/login">{tools.translate('Already have an account? You can connect now to your profile')}</Link>

						</div>
					</form>
					<div>{errorState.error === 'error' ? (<><div className="app-error">{tools.translate('An error occurred')}</div></>) : (<></>)}</div>
					<div>
						<p className="loginMessage"></p>
					</div>
				</div> {/*end app login wrap*/}
			</div>
		</div>
		{/*<Footer/>*/}
	</>
}


export default Subscribe;
