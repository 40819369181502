

const articleTypes = [{name: 'Notes', 
value: 'notes'}, 
{name: 'Blog', 
value: 'blog'}, 
{name: 'Services', 
value: 'services'}, 
{name: 'Portfolio', 
value: 'portfolio'}, 
{name: 'Ads', 
value: 'ads'}, 
{name: 'Templates', 
value: 'templates'}, 
{name: 'Todo', 
value: 'todo'},
{name: 'Books', 
value: 'books'}, 
{name: 'Tutorial', 
value: 'tutorial'}, 

]

export default articleTypes;