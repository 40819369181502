import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck, FaDownload } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck, HiUser } from "react-icons/hi";
import { BsArrowRight as ArrowRight, BsThreeDotsVertical as MobileDotMenu } from "react-icons/bs";
import Header from "../header/Header";
import HeaderLogin from "../header/HeaderLogin";
import Footer from "../footer/Footer";
import parse from "html-react-parser";
import GasManagementHeader from "./header/GasManagementHeader";
// import AddNote from "./AddNote";
// import ProfileMenu from "../ProfileMenu";
// import UserFiles from "./UserFiles";
// import ProfileCover from "./ProfileCover";
import * as tools from "../utils/tools";
import AppContext from "../context/AppContext";
import { Button } from "@mui/material";

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded';
import { FaUserCircle } from "react-icons/fa";

const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';



const EmployeeProfileMenu = (prop: any) => {
    const navigate = useNavigate();
    const { userInfo } = React.useContext(AppContext);

    React.useEffect(() => {

    }, []);

    return <><div className="dashboard-core-leftmenu">
        <div className="user-profile-icon" style={{ display: "flex", justifyContent: "center", marginBottom: "25px" }}>
            <Link to="/"><FaUserCircle style={{ width: "50px", height: "50px", fill: "#808080" }} /></Link>
        </div>
        {/* <ul>
                <li><Link to="/">Accueil</Link></li>
                <li><Link to="/gas">Consommation Carburant</Link></li> 
                <li><Link to="/alerts">Notification</Link></li>
                <li><Link to="/about">A propos</Link></li>
             </ul> */}
        <nav>
            <ul>
                <li><Link to="/"><Button aria-label="Dashboard"
                    startIcon={<HomeRoundedIcon />}>
                    Accueil
                </Button></Link>
                </li>
                <li><Link to="/fuelconsumption"><Button aria-label="Adding user"
                    startIcon={<LocalGasStationRoundedIcon />}>
                    Saisie consommation
                </Button></Link></li>

                <li><Link to="/fuelconsumption"><Button aria-label="Adding user"
                    startIcon={<LocalGasStationRoundedIcon />}>
                    Approvisionnement
                </Button></Link></li>
                <li><Link to="/gas_assignment">
                    <Button aria-label="Dashboard"
                        startIcon={<CircleNotificationsRoundedIcon />}>
                        Notifications
                    </Button></Link>
                </li>
                {/* <li><Link to="/consuming">
                    <Button aria-label="Historique consommation carburant"
                        startIcon={<AccountCircleRoundedIcon />}>
                        Historique consommation Carburant
                    </Button>
                </Link></li> */}
                <li><Link to="/profile">
                    <Button aria-label="Paramètres"
                        startIcon={<SettingsRoundedIcon />}>
                        Paramètres
                    </Button>
                </Link></li>
            </ul>
        </nav>
    </div></>;
}


export default EmployeeProfileMenu;
