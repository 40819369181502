import React, { useState } from "react";
import axios from "axios";
// import AdminMenu from "./AdminMenu";
import { NavLink, Link } from "react-router-dom";
import { HiOutlineDocumentDownload as DownloadIcon } from "react-icons/hi";
import {IoAddCircleOutline as AddUploadIcon} from "react-icons/io5";
//import * as utils from "../../utils/translate.js";
// import imgfolder from "../../../server/public"
//const baseURL:string=process.env.REACT_APP_API_URL+'';
//const appHost:string=process.env.REACT_APP_APP_HOST+'';
import * as tools from "../../utils/tools";
const defaultURL = process.env.REACT_APP_API_URL + "uploaduser";
const uploadURL = process.env.REACT_APP_API_URL + "/upload/";
const apiURL = process.env.REACT_APP_API_URL;

export const DashboardFileUpload = (prop: any) => {
  const [state, setState]: [any, any] = useState({
    files: [],
    selectedFile: null,
    loaded: 0,
  });
  const [mediaState, setMediaState]: [any, any] = useState({
    selected: 0,
    showMediaDialog: false,
    selectedFile: ''
  }); 
  const getAllFiles = () => {
    axios.get(defaultURL).then((response) => {
      console.log("response: ", response.data);
      // return response.data;
      setState({
        ...state,
        files: response.data,
      });
    });
    // return [];
  };

  const onFileChange = (event: any) => {
    // console.log(event.target.files[0]);
    setState({
      ...state,
      selectedFile: event.target.files[0],
    });
    // onFileUpload();
  };

  const onFileUpload = () => {
    //create an object of formData
    const formData: any = new FormData();

    //update the formData object
    formData.append(
      "myFile",
      state.selectedFile,
      state.selectedFile.name.replaceAll(" ", "_")
    );

    formData.append("header", localStorage.getItem("loginToken"));

    setState({
      ...state,
      loaded: 0
    });

    // prop.uploadedProp(false);
    //details of the uploaded file
    console.log(state.selectedFile);

    //request made to the backend api
    //send formData object
    axios
      .post(tools.getHost()+'adminapi/upload/', formData, {
        onUploadProgress: (ProgressEvent: any) => {
          setState({
            ...state,
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        // console.log(res.statusText);
        console.log('uploaded status ',res.data); 
        if(res.data.status.toLowerCase()==='success'){
          prop.isFileUploaded(true);
        }else{
          prop.isFileUploaded(false);
        }
        //getAllFiles();
        // prop.uploadedProp(true);
      });
  };

  React.useEffect(() => {
    getAllFiles();
  }, []);

  //media modal
  const showMedia = (e: any) => {
    let id = (e.target.id).replace('image', '');
    setMediaState({
      ...mediaState,
      selected: id,
      showMediaDialog: true
    });
    getMedia(id);
  }
  const closeMediaModal = () => {
    setMediaState({
      ...mediaState,
      showMediaDialog: false
    });
  }

  const getMedia = (id: any) => {
    console.log(defaultURL);
    axios.get(apiURL + "/files.php?id=" + id).then((response) => {
      console.log("response: ", response.data);
      // return response.data;
      setMediaState({
        ...mediaState,
        selectedFile: JSON.parse(response.data[0]),
        showMediaDialog: true
      });
    });
  }
  return (
    <div>

      <div className="modalContainer"
        style={{ display: mediaState.showMediaDialog ? "block" : "none" }} >
        <div className="modalBox" style={{
          width: '80%', margin: '0 auto', display: 'block',
          position: 'relative', left: '1%', minHeight: '250px', height: '80%'
        }}>
          {/* <div className="closeModalBt" onClick={closeMediaModal}>x</div> */}
          <div className="mainContentPanel">
            {/*<div>{mediaState.selected}</div>*/}

            {mediaState.selectedFile.filetype == "video" ? (

              <><video
                className="appMedia"
                preload="none"
                controls
                src={uploadURL + "users/video/" + mediaState.selectedFile.filename}

              ></video></>
            ) : (<></>

            )}
            {mediaState.selectedFile.filetype == "image" ? (

              (<img
                className="appMedia mainImage"

                src={uploadURL + "users/image/" + mediaState.selectedFile.filename}

              />)
            ) : (
              (<div className="ib"><a href={uploadURL + "users/source/" + mediaState.selectedFile.filename}>{mediaState.selectedFile.filename} <DownloadIcon className="icon" /></a></div>)


            )}
            <br />
          </div>
          <div className="commentsPanel">
            Comments<br /><br />
            <input type="Text" />
          </div>
        </div>
      </div>
      <div>
        <div className="uploadContainer">
          {/*<input type="text" placeholder="titre de votre fichier" />*/}
          <br />
          <label htmlFor="uploadFile" className="transparent-bt elem-text-center" style={{marginBottom:'10px'}}><AddUploadIcon style={{fontSize: '25px'}}/>{state.selectedFile ? state.selectedFile.name : 'choisir un fichier'}</label>
          <input type="file" style={{ display: "none" }} id="uploadFile" onChange={onFileChange} />
          <button style={{ display: state.selectedFile?"flex":"none" }} className="app-bt" onClick={onFileUpload}>upload</button>
        </div>
        <br />
        {/* <div className="form-group" >
            <Progress max="100" color="success" value={state.loaded}>{Math.round(state.loaded, 2)}%</Progress>
            </div> */}
        <div className="uploadLevelContainer" style={{ display: state.loaded>0?"block":"none" }}>
          <div className="uploadLevel" style={{ width: state.loaded + "%" }}>
            {/*{state.loaded}%*/}
          </div>
        </div>
      </div>
      {/* <p>total: {state.files.length}</p> */}
      {/* <div className="appAlbum">
      {state.files.map((res) => {
        res=JSON.parse(res);
        if (/video|mp4/.test(res.filetype)) {
          //videos
          return (
            <div className="ib">
              <video  
                className="appMedia"
                preload="none"
                controls
                src={uploadURL+"users/video/" + res.filename}
                onClick={showMedia }
                id={"image"+res.id}
              ></video>
            </div>
          );
        } else if (/image|jpg|jpeg|png/.test(res.filetype)) {
          //image
          return (
            <div className="ib">
              <img
                className="appMedia appImg"
                alt={res.filename}
                src={uploadURL+"users/image/" + res.filename}
                onClick={showMedia }
                id={"image"+res.id}
              />
            </div>
          );
        } else {
          //link
          return (<div className="ib"><a id={"image"+res.id} href={uploadURL+"users/source/"+res.filename} onClick={showMedia}>{res.filename} <DownloadIcon className="icon" /></a></div>);
        }
        
        return (
          <div>
            {res.filename}
            <br />
            {res.filetype}
            <br />
          </div>
        );
      })}</div> */}
    </div>
  );
}

export default DashboardFileUpload;
