import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import * as Tools from "../../utils/tools";


// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function InputConsumptionGasDataTable(prop: any) {

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'quantity', headerName: 'Litres', width: 130 },
    // { field: 'userId', headerName: 'Employé', width: 130 },

    { field: 'reason', headerName: 'Raison', width: 130 },
    { field: 'distance', headerName: 'Distance(Km)', width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 91,
    // },
    // {
    //   field: 'userLogin',
    //   headerName: 'Litre',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   //   valueGetter: (params: GridValueGetterParams) =>
    //   //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   // },
    // },
    // {
    //   field: 'userJob',
    //   headerName: 'Poste',
    //   description: 'Le nom du poste',
    //   sortable: false,
    //   width: 160
    // },
    {
      field: 'operations',
      headerName: 'Operations',
      description: 'This column contains buttons to update each row',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        // console.log(params);
        const editUser = (e: any) => {
          console.log('editiing ' + params.id);
          prop.toggleUpdate(true, params.id);
        }
        const deleteUser = (e: any) => {
          console.log('deleting ' + params.id);
          prop.toggleDelete(true, params.id);
          //prop.toggleUpdate(true, params.id);
        }
        return <>
          <IconButton aria-label="Modification employe" color="primary" onClick={editUser} ><ModeEditSharpIcon /></IconButton>
          <IconButton arial-label="Delete employe" color="secondary" onClick={deleteUser}><DeleteOutlineOutlinedIcon /></IconButton>
        </>
      }
    }
  ];
  const [employees, setEmployees]: [any, any] = React.useState({
    all: []
  })

  const getGasAssignmentRecord = () => {
    axios.post(Tools.getHost() + "/api/getallfuelconsumptionbyuser/", { userId: localStorage.getItem("userId") })
      .then(res => {
        // console.log(res.data);
        if (res.data.length) {

          const totalLength = res.data.length;
          if (res.data[totalLength - 1].status === 'success') {
            // console.log('my data');
            //format employees 

            // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
            let formatedDatas = [];
            // formatedDatas.push({ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 })
            for (let i = 0, c = res.data.length - 1; i < c; i++) {
              // console.log(res.data[i].userName);
              formatedDatas.push({ id: res.data[i].id, quantity: res.data[i].quantity + "L", userId: res.data[i].userId, reason: res.data[i].reason, distance: res.data[i].distance_km + "km" });

            }
            //console.log('formated datas: ');
            //console.log(formatedDatas);
            setEmployees({
              ...employees,
              all: formatedDatas
            });
          } else {
            // console.log('error');
          }
        } else {
          // console.log('error');
        }

      })
  }

  React.useEffect(() => {
    getGasAssignmentRecord();
  }, [prop.newUserCreated, prop.userUpdated, prop.userDeleted])

  return (
    <div className="datatable-custom" style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={employees.all}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}