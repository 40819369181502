import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck, FaDownload } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck, HiUser } from "react-icons/hi";
import { BsArrowRight as ArrowRight, BsThreeDotsVertical as MobileDotMenu } from "react-icons/bs";
import Header from "../header/Header";
import HeaderLogin from "../header/HeaderLogin";
import Footer from "../footer/Footer";
import parse from "html-react-parser";
import GasManagementHeader from "./header/GasManagementHeader";
import { Alert, Typography } from "@mui/material";
// import AddNote from "./AddNote";
// import ProfileMenu from "../ProfileMenu";
// import UserFiles from "./UserFiles";
// import ProfileCover from "./ProfileCover";
import * as tools from "../utils/tools";
import AppContext from "../context/AppContext";
import EmployeeProfileMenu from "./EmployeeProfileMenu";
import IconButton from "@mui/material/IconButton";
import InputConsumptionGasList from "./input-consumption/InputConsumptionGasList";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';



const InputFuelConsumption = (prop: any) => {
    const navigate = useNavigate();

    const logout = () => {
        prop.loginProp(false);
        localStorage.removeItem('loginToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userDateCreation');
        navigate('/login/');
    }
    const [userState, setUserState] = useState({
        userEmail: '',
        userName: ''
    });

    const getUserData = () => {
        axios.post(baseURL + 'getuserdata/',
            {
                header: localStorage.getItem("loginToken")
            }).then((response: any) => {
                console.log('user name', response.data.userName);
                setUserState({
                    ...userState,
                    userName: response.data.user.userName
                });
            })
    }

    const changeProfilePicDialog = () => {

    }

    const { userInfo } = React.useContext(AppContext);

    React.useEffect(() => {
        // getUserData();
    }, []);

    return <>
        {/*
<HeaderLogin isLoggedInProp={prop.isLoggedInProp} />
*/}
        <GasManagementHeader />

        <div className="dashboard-core">
            <div className="left-content">
                <EmployeeProfileMenu />
            </div>
            <div className="main-content">
                {/* <h2 className="elem-light-title">Saisie de votre consommation en carburant</h2>
                <div className="basic-dashboard-stats">

                </div> */}
                <InputConsumptionGasList/>
            </div>
        </div>

    </>;
}


export default InputFuelConsumption;
