import React from "react";
import { Link } from "react-router-dom";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Button, IconButton } from "@mui/material";
import { HomeRounded } from "@mui/icons-material";
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'; 
import ArticleIcon from '@mui/icons-material/Article';
import MessageIcon from '@mui/icons-material/Message';
import EditNoteIcon from '@mui/icons-material/EditNote';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const LeftMenu = () => {

    return <>
        <div className="dashboard-core-leftmenu">
            <h2 className="dashboard-name">Templates Manager v1.0</h2>
            <nav>
                <ul>
                    <li><Link to="/">
                        <Button aria-label="Dashboard"
                            startIcon={<BarChartRoundedIcon />}>
                            Dashboard
                        </Button></Link>
                    </li>
                    <li><Link to="/articles"><Button aria-label="Adding new article"
                        startIcon={<EditNoteIcon/>}>
                        Gestion articles
                    </Button></Link></li>
                    <li><Link to="/files"><Button aria-label="Adding new article"
                        startIcon={<InsertDriveFileIcon/>}>
                        Gestion fichiers
                    </Button></Link></li>
                    <li><Link to="/templates"><Button aria-label="gestion templates"
                        startIcon={<InsertDriveFileIcon/>}>
                        Gestion templates
                    </Button></Link></li>
                    <li><Link to="/users"><Button aria-label="Adding user"
                        startIcon={<AccountCircleRoundedIcon />}>
                        Gestion utilisateur
                    </Button></Link></li>
                    <li><Link to="/messages"><Button aria-label="Inbox"
                        startIcon={<MessageIcon />}>
                        Gestion messages
                    </Button></Link></li>
                    <li><Link to="/messages"><Button aria-label="Inbox"
                        startIcon={<MessageIcon />}>
                        Gestion devis
                    </Button></Link></li>
                    <li><Link to="/messages"><Button aria-label="Inbox"
                        startIcon={<MessageIcon />}>
                        Gestion Clients
                    </Button></Link></li>
                    {/* <li><Link to="/gas_assignment">
                        <Button aria-label="Dashboard"
                            startIcon={<LocalGasStationIcon/>}>
                            Gestion carburant
                        </Button></Link>
                    </li> */}
                    {/* <li><Link to="/consuming">
                        <Button aria-label="Historique consommation carburant"
                            startIcon={<HistoryRoundedIcon />}>
                            Historique consommation Carburant
                        </Button>
                    </Link></li> */}
                    <li><Link to="/settings">
                        <Button aria-label="Paramètres"
                            startIcon={<SettingsRoundedIcon />}>
                            Paramètres
                        </Button>
                    </Link></li>
                </ul>
            </nav>
        </div>
    </>
}


export default LeftMenu;